<template>
<!-- 左上两个 -->
    <div class="peoplenav">
        <div class="xianshi darkBack">
            <div>
                <div>健身房运动时长总排名(时)</div>
            </div>
            <div id="histogram1"></div>
        </div>
        <div class="xianshi darkBack">
            <div>
                <div>健身房数量总排名</div>
            </div>
            <div id="histogram2"></div>
        </div>
    </div>
</template>

<script>
import {timestamp} from "/src/assets/js/websocket";
import bus from "/src/assets/js/bus";

export default {
    data () {
        return {
            xianjiOption:[
                {
                    data:["新昌县", "诸暨市", "柯桥区", "越城区", "上虞区", "嵊州市"],
                    number:["65", "43", "34", "32", "28", "25"],
                    max:5,
                    color1: "#2D94D0",          // y轴左侧轴的颜色
                    color2: "#2D94D040",         // 网格线的颜色
                    color3: "#2D94D0",          // 渐变色初始颜色             
                    color4: "#3AE6CE",          // 渐变色末尾颜色
                    color5: "#3AE5CE",          // 柱状图顶部的颜色
                },
                {
                    data:["新昌县", "诸暨市", "柯桥区", "越城区", "上虞区", "嵊州市"],
                    number:["4021", "1648", "3341", "2987", "2833", "2315"],
                    max:5,
                    color1: "#E5823A",          // y轴左侧轴的颜色
                    color2: "#E9AC3140",         // 网格线的颜色
                    color3: "#E5813A",          // 渐变色初始颜色             
                    color4: "#EFDC29",          // 渐变色末尾颜色
                    color5: "#EFDB29",          // 柱状图顶部的颜色
                },
            ]
        }
    },
    methods: {
        fitness(id, number){
            let that = this;
            // let canye = this.$echarts.init(document.getElementById(id));
            let canye = this.$echarts.getInstanceByDom(document.getElementById(id))
            if (canye == null) { // 如果不存在，就进行初始化
                canye = this.$echarts.init(document.getElementById(id));
            }
            // var data = number.number;

            let option = {
                 xAxis: {
                    // max: 'dataMax',
                    show:true,
                    type: 'value',
                    "axisLine":{     //x轴坐标轴
                        "show":false,
                    },
                    axisLabel:{
                        show: false,
                    },
                    
                    axisTick: {		//x轴刻度线
                        show:false
                    },
                    splitNumber: 4,
                    splitLine: {            // 网格线的颜色
                        show: true,
                        lineStyle:{
                            color: number.color2,
                            width: 1,
                            type: 'solid',
                        }
                　　}
                },
                grid: {         // 间距
                    left: '8%',  
                    right: '16%', 
                    top:'6%', 
                    bottom: '1%',  
                    containLabel: true,
                },
                yAxis: {
                    type: 'category',
                    data: number.data,
                    inverse: true,              // 上下反向
                    animationDuration: 300,
                    animationDurationUpdate: 300,
                    max: number.max,          // y轴显示显示最大的条数 
                    axisLine:{              // y轴左侧的样式
                        show:true,
                        lineStyle: {
                            color: number.color1,
                        }
                    },
                    splitLine:{show: false},
                    axisTick: {                 // y轴刻度线
                        show:false,
                        alignWithLabel: true,
                    },
                    splitArea : {show : false}, 
                    // axisLabel: {               // y轴字体的颜色
                    //     textStyle: {
                    //         show:true,
                    //         fontFamily:'微软雅黑',
                    //         color: "#7EA7E2",
                    //         fontSize: '0.12rem',
                    //     },                           
                    // },
                    axisLabel:{
                        show:true,
                        formatter:function(value){
                            var texts=value;
                            if(texts.length>5){ // 具体多少字就看自己了
                                texts=texts.substr(0,5)+'...';
                            }
                            return texts;
                        },
                        textStyle: {
                            fontFamily:'微软雅黑',
                            color: "#7EA7E2",
                            fontSize: '0.12rem',
                        },
                    }
                },
                series: [{
                    // realtimeSort: true,
                    // name: 'X',
                    type: 'bar',
                    animation: true,
                    animationDuration: 500,
                    barWidth : '40%',              // 柱子的宽度
                    data: number.number,
                    itemStyle: {
                        normal: {
                            color: this.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
                                offset: 0,
                                color: number.color3 // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: number.color4 // 100% 处的颜色
                            }], false)
                        }
                    },
                    label: {
                        formatter: "{c}",
                        show: true,
                        position: 'right',
                        valueAnimation: true,
                        color: number.color5,    // 柱状图上字体的颜色
                        fontSize:'0.13rem',
                    },
                }],
                legend: {
                    show: true
                },
                animationDuration: 0,
                animationDurationUpdate: 3000,
                animationEasing: 'linear',
                animationEasingUpdate: 'linear'
            };
            canye.setOption(option);

            //图表自动缩放
            window.addEventListener("resize",function(){
                canye.resize();
            });
        },  
        //获取健身房数量排名数据
        getNumData(){
            let that = this;

            let data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            // 请求健身房数量排名
            let url1 = window.appHost+"/api/v1/screen/Venue/RaningAreaGym"
            let result = window.ajaxRequest(url1,JSON.stringify(data),"post")
            // console.log(result)
            
            that.xianjiOption[1].data = [];
            that.xianjiOption[1].number = [];
            for(let i in result.msg){
                // console.log(i,result.msg[i])
                that.xianjiOption[1].data.push(i);
                that.xianjiOption[1].number.push(result.msg[i]);
            }
            that.fitness("histogram2", that.xianjiOption[1]);

            
        },
        // 获取健身房时长排名
        getTimeData(type,name){
            let that = this;

            let data = {
                token: window.getToken(),
                app_id: window.appId,
                time: timestamp(),
            }
            
            let url2;
            if(type == '市') url2 = window.appHost+"api/v1/screen/Venue/durationRankingDate"
            else url2 = window.appHost+"api/v1/screen/Venue/durationRankingDate?area="+name

             
            let res = window.ajaxRequest(url2,JSON.stringify(data),"post")
            // console.log(res)
            
            that.xianjiOption[0].data = [];
            that.xianjiOption[0].number = [];
            if(res.code == 1){
                res.data.forEach((item,index)=>{
                    that.xianjiOption[0].data.push(item.title);
                    that.xianjiOption[0].number.push(item.duration);
                })
            }
            that.fitness("histogram1", that.xianjiOption[0]);

        }
    },
    mounted () {
        // this.fitness("histogram1", this.xianjiOption[0]);
        this.getNumData();
        let info = JSON.parse(window.localStorage.getItem('screenUserInfo'))
        // console.log(info)
        if(info.is_city == 1) this.getTimeData('市','绍兴市');
        else this.getTimeData('区',info.name);

        bus.$on('jianshenfangArea',res=>{
            // console.log(res)
            if(res == '绍兴市') this.getTimeData('市','绍兴市');
            else this.getTimeData('区',res);
        })
    },
    beforeDestroy(){
        bus.$off();
    }
}
</script>

<style scoped>
.peoplenav{
    width:5.06rem;
    height:2.8rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}
.xianshi{
    width:2.43rem;
    height:2.8rem;
    padding: 0.2rem;
    font-size: 0.17rem;
    color:#fff;
}
.xianshi>div{
    height:0.35rem;
    text-align: left;
}
#histogram1,#histogram2{
    width:2.40rem;
    height:2.00rem;
    margin-left:-0.2rem;
    /* border:1px solid red; */
}
</style>